body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#stop-user{
  /*background-color:  rgba(253,214,77,0.5)!important; yellow*/
  background-color:  rgba(0,0,0,0.2)!important;
  z-index: 10000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  cursor: wait;
}

#stop-user>div{
  position: absolute;
  top: 40%; 
  left: 50%;
  font-weight: bold;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #000000;
}